import * as React from "react"
import PropTypes from "prop-types"
import useActions from "./actions"

import reducer, { initialState } from "./reducer"

let SpaceProfileContext = React.createContext({
  commonData: initialState,
  actions: {
    setContextMinDate: () => null,
    setContextMaxDate: () => null,
    setContextDate: () => null,
    setContextTime: () => null,
    setContextTimes: () => null,
    setBookTourStep: () => null,
    setBookTour: () => null,
    setGetInTouch: () => null,
    resetState: () => null,
  },
})

function SpaceProfileContextProvider({ children }) {
  let [commonData, dispatch] = React.useReducer(reducer, initialState)

  const actions = useActions(commonData, dispatch)

  return (
    <SpaceProfileContext.Provider value={{ commonData, actions }}>
      {children}
    </SpaceProfileContext.Provider>
  )
}

SpaceProfileContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

let SpaceProfileContextConsumer = SpaceProfileContext.Consumer

export {
  SpaceProfileContext,
  SpaceProfileContextProvider,
  SpaceProfileContextConsumer,
}
