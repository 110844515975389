export const SET_CONTEXT_MIN_DATE = "SET_CONTEXT_MIN_DATE"
export const SET_CONTEXT_MAX_DATE = "SET_CONTEXT_MAX_DATE"
export const SET_CONTEXT_DATE = "SET_CONTEXT_DATE"

export const SET_CONTEXT_TIME = "SET_CONTEXT_TIME"
export const SET_CONTEXT_TIMES = "SET_CONTEXT_TIMES"

export const SET_BOOK_TOUR_STEP = "SET_BOOK_TOUR_STEP"
export const SET_BOOK_TOUR = "SET_BOOK_TOUR"
export const SET_GET_IN_TOUCH = "SET_GET_IN_TOUCH"
export const SET_VALUE = "SET_VALUE"

export const SET_IMAGE_GALLERY_INDEX = "SET_IMAGE_GALLERY_INDEX"
export const SET_IMAGE_GALLERY_OPEN = "SET_IMAGE_GALLERY_OPEN"

export const RESET_STATE = "RESET_STATE"
