import React from "react"
import { SpaceProfileContextProvider } from "./src/components/SpaceProfile/context"

import "bootstrap/dist/css/bootstrap.min.css"
import "./src/styles/base.less"
import "react-18-image-lightbox/style.css" // This only needs to be imported once in your app
import "react-datepicker/dist/react-datepicker.css"

export const wrapRootElement = ({ element }) => {
  return <SpaceProfileContextProvider>{element}</SpaceProfileContextProvider>
}
